import React from 'react';
import ReactDOM from 'react-dom';
import MedAssistWidget from './components/MedAssistWidget';

// Define the initMedAssistWidget function
const initMedAssistWidget = ({ practitionerUuid, patientUuid, caseContextUuid, platformUuid, providerUuid, apiToken }) => {
  const widgetContainer = document.getElementById('medassist-widget-container');
  
  if (widgetContainer) {
    ReactDOM.render(
      <MedAssistWidget
        practitionerUuid={practitionerUuid}
        patientUuid={patientUuid}
        caseContextUuid={caseContextUuid}
        platformUuid={platformUuid}
        providerUuid={providerUuid}
        apiToken={apiToken}
      />,
      widgetContainer
    );
  }
};

// Attach initMedAssistWidget to the window object to make it globally accessible
window.initMedAssistWidget = initMedAssistWidget;

// Optionally, you can automatically initialize the widget if the UUIDs are already set as global variables
document.addEventListener('DOMContentLoaded', () => {
  if (window.practitionerUuid && window.patientUuid && window.caseContextUuid && window.platformUuid && window.providerUuid && window.apiToken) {
    initMedAssistWidget({
      practitionerUuid: window.practitionerUuid,
      patientUuid: window.patientUuid,
      caseContextUuid: window.caseContextUuid,
      platformUuid: window.platformUuid,
      providerUuid: window.providerUuid,
      apiToken: window.apiToken,
    });
  }
});
