import axios from 'axios';
const token = process.env.REACT_APP_API_TOKEN

// const token = 'Bearer f6fedbab26212329126c3f03b32fa74afa733b87b47816d1522918f91f7ff6e34277789a76b33b71520b83ee259de4ba65d29c2cfe63023c693125d56e3a747328b4148d72919220dff9d250a9c1bba8d15e3d37b4e0ab9b5e00a22e334b1012a0c9597c45287d5b4b815cbe322a7bc5c4289a316636d27d390492ea1992e7b5';

// Function to fetch initial messages
export const getAiAssistance = async (practitionerUuid, caseContextUuid, platformUuid, providerUuid, token) => {
  const base_url = process.env.REACT_APP_API_BASE_URL

  console.log("API BASE URL: ", base_url); 
  try {
    const params = {
      headers: {
        Authorization: "Bearer "+token
      },
      params: {
        practitionerUuid,
        caseContextUuid,
        platformUuid,
        providerUuid
      }
    };
    const response = await axios.get(`https://api.aihxp.com/api/ai-assistance`, params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to send a new message
export const postAiAssistance = async ({ practitionerUuid, caseContextUuid, platformUuid, providerUuid, input, attachments }) => {
  const base_url = process.env.REACT_APP_API_BASE_URL
  console.log("API BASE URL: ", base_url); 
  try {
    const payload = {
      input,
      practitionerUuid,
      caseContextUuid,
      platformUuid,
      providerUuid,
      attachments
    };

    const config = {
      headers: {
        Authorization: "Bearer "+token
      }
    };

    const response = await axios.post(`https://api.aihxp.com/api/ai-assistance`, payload, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};
