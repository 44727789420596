import React, { useState } from 'react';
import ButtonComponent from './ButtonComponent';
import ChatComponent from './ChatComponent';

const MedAssistWidget = ({ practitionerUuid, caseContextUuid, platformUuid, providerUuid, apiToken  }) => {
  const [showChat, setShowChat] = useState(false);

  return (
    <>
      {!showChat && <ButtonComponent onClick={() => setShowChat(true)} />}
      {showChat && (
        <ChatComponent
          onClose={() => setShowChat(false)}
          practitionerUuid={practitionerUuid}
          caseContextUuid={caseContextUuid}
          platformUuid={platformUuid}
          providerUuid={providerUuid}
          apiToken={apiToken}
        />
      )}
    </>
  );
};

export default MedAssistWidget;
