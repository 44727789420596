import React, { useState, useEffect, useRef, useCallback } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import StarIcon from '@mui/icons-material/Star'; 
import GraphIcon from '@mui/icons-material/InsertChartOutlined';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
// import ReactMarkdown from 'react-markdown';
import DOMPurify from 'dompurify';
import { getAiAssistance, postAiAssistance } from '../../src/services/strapi';

const pollingInterval = 2000; // Interval for polling new messages (in milliseconds)

const ChatComponent = ({ onClose, practitionerUuid, caseContextUuid, platformUuid, providerUuid }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false); // State for fetching messages
  const [sending, setSending] = useState(false); // New state for tracking message sending
  const [messageInput, setMessageInput] = useState('');
  const bottomRef = useRef(null);
  const [caseContext, setCaseContext] = useState(null);
  const [patientDetails, setPatientDetails] = useState({ name: '', birthDate: '', vitalSigns: '' });
  // const pollRef = useRef(null); // Reference to hold the polling interval ID

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const aiAssistance = await getAiAssistance(practitionerUuid, caseContextUuid, platformUuid, providerUuid);
      if (aiAssistance) {
        setMessages(aiAssistance.conversation || []);
        setCaseContext(aiAssistance.case_context || null);  // Update caseContext with fetched data
      } else {
        console.log("No data returned from aiAssistance");
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setLoading(false);
    }
  }, [practitionerUuid, caseContextUuid, platformUuid, providerUuid]);
    
  useEffect(() => {
    const intervalId = setInterval(fetchData, pollingInterval);
    return () => clearInterval(intervalId);
  }, [fetchData]);
  
  useEffect(() => {
    // Scroll to bottom on every update to the messages state
    scrollToBottom();
  }, [messages]); // Dependency on messages state
  

  useEffect(() => {
    const extractAndSetPatientDetails = () => {
      if (caseContext && caseContext.bundle && caseContext.bundle.entry) {
        // console.log("caseContext", caseContext);
        const patientResource = caseContext.bundle.entry.find(entry => entry.resource.resourceType === "Patient");
        const observationResource = caseContext.bundle.entry.find(entry => entry.resource.resourceType === "Observation");
    
        let details = { name: '', birthDate: '', vitalSigns: '' };
        if (patientResource) {
          const { name, birthDate } = patientResource.resource;
          details.name = name[0].given.join(" ") + " " + name[0].family;
          details.birthDate = birthDate;
        }
    
        if (observationResource) {
          const { valueQuantity } = observationResource.resource;
          details.vitalSigns = `${valueQuantity.value} ${valueQuantity.unit}`;
        }
        setPatientDetails(details);
      }
    };

    extractAndSetPatientDetails();
  }, [caseContext]);

  const scrollToBottom = () => {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  
  const handleSend = async () => {
    if (!messageInput.trim()) return;
  
    setSending(true);
    try {
      const postData = {
        practitionerUuid,
        caseContextUuid,
        platformUuid,
        providerUuid,
        input: messageInput.trim(),
        attachments: [],
      };
  
      const aiAssistance = await postAiAssistance(postData);
  
      if (aiAssistance && aiAssistance.data && aiAssistance.data.messageInput) {
        const newMessage = {
          type: 'human',
          data: {
            content: aiAssistance.data.messageInput,
            timestamp: new Date().toISOString(),
          },
        };
  
        setMessages(currentMessages => [...currentMessages, newMessage]);
      }
      setMessageInput('');
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setSending(false);
    }
  };
          
  return (
    <>
      <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999 }}    onClick={onClose} ></div>
      <Card style={{ position: 'fixed', bottom: 20, right: 20, minWidth: '45%', maxWidth: '350px', minHeight: '40%', maxHeight: '700px', display: 'flex', flexDirection: 'column', zIndex: 1000 }}>
      <CardHeader
          action={<IconButton onClick={onClose}><CloseIcon /></IconButton>}
          title={`${patientDetails.name ? patientDetails.name : 'Med Assist Chat'}`}
          subheader={
            <>
              {patientDetails.birthDate && (
                <Box component="span" display="flex" alignItems="center">
                  <StarIcon fontSize="small" />
                  <Typography component="span" style={{ marginLeft: 4 }}>
                    {patientDetails.birthDate}
                  </Typography>
                </Box>
              )}
              {patientDetails.vitalSigns && (
                <Box component="span" display="flex" alignItems="center" style={{ marginLeft: 10 }}>
                  <GraphIcon fontSize="small" />
                  <Typography component="span" style={{ marginLeft: 4 }}>
                    {patientDetails.vitalSigns}
                  </Typography>
                </Box>
              )}
            </>
          }
        />
        <CardContent style={{ overflow: 'auto', flexGrow: 1 }}>
        {messages.map((msg, index) => (
          <Box key={index} style={{ display: 'flex', justifyContent: msg.type === 'human' ? 'flex-end' : 'flex-start', margin: '10px' }}>
            <Typography component="div" style={{ maxWidth: '80%', padding: '10px', borderRadius: '10px', borderBottomLeftRadius: msg.type === 'human' ? '10px' : '0px', borderBottomRightRadius: msg.type === 'ai' ? '10px' : '0px', backgroundColor: msg.type === 'human' ? '#f6f6f6' : '#f6f6ff', color: 'grey' }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(msg.data.content) }}></Typography>
          </Box>
        ))}
          <div ref={bottomRef} />
        </CardContent>
        <Box style={{ padding: '10px', display: 'flex', borderTop: '1px solid #ccc' }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Escribe tu pregunta..."
          style={{ marginRight: '10px' }}
          value={messageInput}
          onChange={(e) => setMessageInput(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) { // Check if Enter was pressed without the Shift key
              e.preventDefault(); // Prevent the default Enter key behavior (e.g., form submission)
              handleSend(); // Call the send function
            }
          }}
          onFocus={scrollToBottom} // Add this line to handle the focus event
        />
          <Button variant="contained" color="primary" endIcon={<SendIcon />} onClick={handleSend} disabled={sending}>
        {sending ? <CircularProgress size={24} /> : 'Enviar'}
      </Button>
        </Box>
      </Card>
    </>
  );
};

export default ChatComponent;
