import React from 'react';
import Button from '@mui/material/Button';
import ChatIcon from '@mui/icons-material/Chat';


const ButtonComponent = ({ onClick }) => (
  <div style={{ position: 'fixed', bottom: 20, right: 20 }}>
    <Button 
      variant="contained" 
      color="primary" 
      startIcon={<ChatIcon />} 
      onClick={onClick}
      style={{ textTransform: 'none' }}
    >
      Abrir MedAssist
    </Button>
  </div>
);

export default ButtonComponent;
